
import {
  attachments,
  HeaderKeys,
  IProject,
  IProjectAreaOfInterest,
  IProjectReport,
  IReportHeaderTemplate,
  notes,
  projects,
  setHeaders,
} from "@/api";
import Vue from "vue";
import EditableLabel from "@/components/common/EditableLabel.vue";
import ProjectNotes from "@/components/project/ProjectNotes.vue";
import ProjectScans from "@/components/project/scans/ProjectScans.vue";
import ProjectAreaOfInterestNotes from "@/components/project/ProjectAreaOfInterestNotes.vue";
import ReportShareModal from "@/components/reports/report/reportContent/ReportShareModal.vue";
import ReportHeader from "@/components/reports/report/reportContent/ReportHeader.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import ReportPasswordDialog from "@/components/reports/report/reportContent/ReportPasswordDialog.vue";
import ReportTopBar from "@/components/reports/report/reportContent/PublicReportTopBar.vue";
import { getDate, sizeInMB } from "@/utils";
import { Actions, Mutations } from "@/store/models";
import ReportPasswordValidationDialog from "@/components/reports/report/reportContent/ReportPasswordValidationDialog.vue";
import ReportAttachments from "@/components/reports/report/reportContent/reportAttachments/ReportAttachments.vue";
import ProjectAreaOfInterestSelect from "@/components/project/ProjectAreaOfInterestSelect.vue";
import { BroadcaseMessage, Broadcast } from "@/utils/broadcast";
import ReportArchiveDialog from "./reportContent/ReportArchiveDialog.vue";
import PublicReportPasswordErrorDialog from "./reportContent/PublicReportPasswordErrorDialog.vue";
// import GeneratePdfBtn from "@/components/reports/report/reportContent/GeneratePdfBtn.vue";

export default Vue.extend({
  name: "Report",
  components: {
    EditableLabel,
    ProjectNotes,
    ProjectScans,
    ProjectAreaOfInterestNotes,
    ReportShareModal,
    ReportHeader,
    ConfirmationDialog,
    ReportPasswordDialog,
    ReportTopBar,
    ReportPasswordValidationDialog,
    ReportAttachments,
    ReportArchiveDialog,
    PublicReportPasswordErrorDialog,
    // ProjectAreaOfInterestSelect,
    // GeneratePdfBtn,
  },
  data() {
    return {
      passwordDialog: false,
      passwordValidationDialog: false,
      passwordErrorDialog: false,
      archiveDialog: false,
      shareDialog: false,
      deleteConfirmDialog: false,
      unshareDialog: false,
      report: {} as IProjectReport,
      project: {} as IProject,
      areasOfInterest: [] as IProjectAreaOfInterest[],
      selectedAreaOfInterest: {} as IProjectAreaOfInterest & { index: number },
      reportHeader: {} as IReportHeaderTemplate,
      beforeRouteLeaveDialog: false,
      nextPage: {} as any,
      showAOIContainer: false,
    };
  },
  computed: {
    id: function (): number {
      return Number(this.$route.params.id);
    },
    isLocked: function (): boolean {
      return true;
    },
    shared: function (): boolean {
      return !!this.report?.share_key;
    },
    disabledReportHeaderEdit: function (): boolean {
      // return this.shared
      // disabling edit report and save as template options
      return true;
    },
    projectDetails: function (): any[] {
      return [
        // {
        //   label: "Project Address",
        //   text: this.project.address,
        //   icon: "mdi-map-marker",
        // },
        {
          label: "File Size",
          text: sizeInMB(this.project.size),
          icon: "mdi-database",
        },
        {
          label: "Last Updated",
          text: getDate(
            this.project.updated_datetime || this.project.created_datetime
          ),
          icon: "mdi-calendar-month",
        },
      ];
    },
    isPublic: function (): boolean {
      return !this.id;
    },
    link: function (): string {
      return window.location.origin + "/public/report/" + this.report.share_key;
    },
    showAttachments: function (): boolean {
      return this.report.sites_data_allowed;
    },
    createdDate: function (): string {
      return getDate(this.report?.created_datetime);
    },
  },
  mounted() {
    this.init();
    const password = this.$route.query.password as string;
    if (this.isPublic) {
      if (!password) {
        this.passwordValidationDialog = true;
      } else {
        this.validatePassword(password);
      }
    } else {
      this.getReport();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      !this.shared &&
      !to.path.includes("projects") &&
      !to.path.includes("reports")
    ) {
      this.beforeRouteLeaveDialog = true;
      this.nextPage = () => {
        projects.reports.delete(this.id);
        next();
      };
      return;
    }
    next();
  },
  methods: {
    async init() {
      if (!this.selectedAreaOfInterest.id) {
        return;
      }
      const note = (
        await notes.areaOfInterest.get(this.selectedAreaOfInterest.id)
      ).data[0];
      const items = (
        await attachments.projects.getAll(
          this.project.id,
          this.selectedAreaOfInterest.id
        )
      ).data;

      if ((note && note.note) || items.length) {
        this.showAOIContainer = true
      }
    },
    toggleShareDialog(val: boolean) {
      this.shareDialog = val;
    },
    toggleDeleteConfirmDialog(val: boolean) {
      this.deleteConfirmDialog = val;
    },
    toggleUnshareDialog(val: boolean) {
      this.unshareDialog = val;
    },
    async getReport() {
      try {
        this.report = (await projects.reports.get(this.id)).data[0];
        this.project = { ...this.report.project, status: 0 } as any; // defaulting project to locked state
        this.getAreasOfInterest();
        this.getReportHeader();
      } catch (e) {
        if (this.isPublic) {
          this.passwordErrorDialog = true;
        } else {
          this.$store.dispatch(Actions.DisplayError, e);
        }
      }
    },
    async getAreasOfInterest() {
      const response = (await projects.areaOfInterests.getAll(this.project.id))
        .data;
      this.areasOfInterest = response.filter((aoi) =>
        this.report.project_reports_areas_of_interests.find(
          (reportArea) => reportArea.projects_areas_of_interest.id === aoi.id
        )
      );
      if (!this.selectedAreaOfInterest.id) {
        this.selectedAreaOfInterest =
          { ...this.areasOfInterest[0], index: 0 } || {};
      }
    },
    prevArea() {
      let index = this.selectedAreaOfInterest.index - 1;
      if (index < 0) {
        index = this.areasOfInterest.length - 1;
      }
      this.selectedAreaOfInterest = { ...this.areasOfInterest[index], index };
    },
    nextArea() {
      let index = this.selectedAreaOfInterest.index + 1;
      if (index === this.areasOfInterest.length) {
        index = 0;
      }
      this.selectedAreaOfInterest = { ...this.areasOfInterest[index], index };
    },

    async getReportHeader() {
      this.reportHeader = {
        ...this.report.report_header_template,
        ...this.report.report_header_json,
      };
    },
    async deleteReport() {
      try {
        await projects.reports.delete(this.id);
        this.$router.push("/projects/" + this.project.id);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.link);
    },
    async share(password: string) {
      try {
        this.report = (
          await projects.reports.update(this.id, {
            preview: false,
          })
        ).data;
        this.report = (await projects.reports.share(this.id, password)).data;
        this.toggleShareDialog(true);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async unshare() {
      try {
        await projects.reports.unshare(this.id);
        this.$router.replace("/reports");
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async generatePDF(name: string) {
      const url =
        window.location.origin + this.$route.path + "/pdf?name=" + name;
      window.open(url, "_blank");

      const bc = Broadcast.getInstance();

      bc.onmessage = (ev) => {
        if (ev.data === BroadcaseMessage.PdfGenerated) {
          this.$store.commit(Mutations.SetToast, {
            text: "Archive PDF Complete",
          });
        }
      };
    },
    async download() {
      try {
        await projects.reports.downloadPDF(this.report.id);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    validatePassword(val: string) {
      setHeaders([
        {
          key: HeaderKeys.Password,
          value: val,
        },
      ]);
      this.getReport();
      this.passwordValidationDialog = false;
    },
  },
  watch: {
    selectedAreaOfInterest: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
});
