<template>
  <v-app-bar>
    <div class="d-flex align-center">
      <span class="caption subtitle-2">
        Report Created on:
        <span>{{ createdOnDate }}</span>
        <span class="hide-on-xs">{{ createdOnTime }}</span>
        <span class="pr-5"></span>
      </span>

      <span class="caption">
        Report By:
        <span class="font-weight-light pr-5">{{ report.updated_by }}</span>
      </span>
      <span class="caption">
        Company:
        <span class="font-weight-light">{{ report.company.name }}</span>
      </span>
    </div>

    <!-- <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn plain small @click="download">
          <v-icon>mdi-file-download</v-icon>Download PDF
        </v-btn>
      </v-col>
    </v-row> -->
    <!-- <v-btn class="ma-2" small outlined @click="contact"> Contact </v-btn> -->

    <template v-slot:extension>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn plain small @click="download">
          <v-icon>mdi-file-download</v-icon>Download PDF
        </v-btn>
      </v-col>
    </template>
  </v-app-bar>
</template>

<script>
import { Mutations } from "@/store/models";
import { getDate, getTime } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "PublicReportTopBar",
  props: {
    report: Object,
  },
  data() {
    return {};
  },
  computed: {
    createdOnDate: function () {
      const date = this.report.created_datetime;
      return getDate(date);
    },
    createdOnTime: function () {
      const date = this.report.created_datetime;
      return " at " + getTime(date);
    },
  },
  methods: {
    contact() {
      this.$store.commit(Mutations.SetSupportDialog, true);
    },
    download() {
      const encoded = window.btoa(this.report.password);
      window.open(
        window.location.origin + this.$route.path + "/pdf?download=" + encoded,
        "_blank"
      );
    },
  },
});
</script>
<style lang="scss" >
</style>